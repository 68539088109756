/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { Tab, TabIndicator, TabList, Tabs, VStack } from '@chakra-ui/react';
import React from 'react';

import { useGetAssignmentsQuery } from '@/API/assignments.api';
import { useGetTalliesQuery } from '@/API/tallies.api';
import ViewEditorAssignments from '@/components/view-editor/ViewEditorAssignments';
import ViewEditorTallies from '@/components/view-editor/ViewEditorTallies';
import { useAppSelector } from '@/store/hooks';

enum ViewEditorAssignmentsTalliesTabs {
  ASSIGNMENTS,
  TALLIES,
}

const selectedTabStyle = {
  color: 'white',
};

const ViewEditorAssignmentsTallies = (): React.JSX.Element => {
  const {
    viewDraft: view,
  } = useAppSelector((state) => state.viewEditor);

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<ViewEditorAssignmentsTalliesTabs>(
    ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS,
  );

  // Fetch assignments for the selected departments and templates
  const { data: assignmentsData, isLoading } = useGetAssignmentsQuery(
    {
      departmentList: view.filter.on_departments,
      templateList: view.filter.on_templates,
    },
    // ToDo: This seems wrong
    { skip: !view.filter.on_departments.length && !view.filter.on_templates.length },
  );
  const {
    data: talliesData,
    isLoading: isLoadingTallies,
    isFetching: isFetchingTallies,
  } = useGetTalliesQuery(view?.filter?.on_templates ?? [], {
    skip: !view.filter.on_templates.length || selectedTabIndex !== ViewEditorAssignmentsTalliesTabs.TALLIES,
  });

  const getTopBar = (): React.JSX.Element => {
    return (
      <Tabs
        index={selectedTabIndex}
        isLazy
        onChange={(index) => setSelectedTabIndex(index)}
        position={'relative'}
        variant={'unstyled'}
      >
        <TabList>
          <Tab _selected={selectedTabStyle}>Assignments</Tab>
          <Tab _selected={selectedTabStyle}>Tallies</Tab>
        </TabList>
        <TabIndicator
          bg={'blue.500'}
          borderRadius={'10px'}
          height={'40px'}
          mt={'-40px'}
          position={'inherit'}
          textColor={'white'}
          zIndex={-1}
        />
      </Tabs>
    );
  };

  // ToDo: Handle no assignments found
  return (
    <VStack gap={5} align={'top'}>
      {getTopBar()}
      {selectedTabIndex === ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS ? (
        <ViewEditorAssignments
          assignmentsData={assignmentsData ?? []}
          isAssignmentDataLoading={isLoading}
        />
      ) : (
        <ViewEditorTallies
          isTalliesDataLoading={isLoadingTallies || isFetchingTallies}
          talliesData={talliesData ?? []}
        />
      )}
    </VStack>
  );
};

export default ViewEditorAssignmentsTallies;
