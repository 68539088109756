export type TableOrderDesc = {
  availableItemsDesc: boolean;
  previewItemsDesc: boolean;
};

export enum ViewEditorDrawerMode {
  EDIT,
  CREATE,
}

export enum ViewEditorColumnFilterFields {
  AUTO_ADD_ASSIGNMENTS = 'autoAddAssignments',
  AUTO_ADD_PERSONNEL = 'autoAddPersonnel',
  ASSIGN_STRUCTURE_ID = 'assignStructureId',
  ASSIGN_TYPE_ID = 'assignTypeId',
  DISPLAY_NAME = 'displayName',
  INACTIVE_ASSIGNMENTS = 'expired',
}

export enum PersonnelViewMembershipAccessTabs {
  ACCESS,
  MEMBERSHIP,
}
