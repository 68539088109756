import { HttpMethods } from 'msw';

import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import Assignment, { AssignmentDTO, AssignmentType, AssignmentTypeDTO } from '@/types/assignment.types';

export interface AssignmentFilter {
  departmentList?: number[];
  templateList?: number[];
}

export const assignmentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignmentTypes: builder.query<AssignmentType[], void>({
      query: () => Endpoints.AssignmentsType,
      transformResponse: (response: AssignmentTypeDTO[]) => {
        return response.map((assignmentType) => ({
          atypeId: assignmentType.atype_id,
          name: assignmentType.name,
        }));
      },
    }),
    getAssignments: builder.query<Assignment[], AssignmentFilter>({
      query: (filter) => ({
        body: {
          DepartmentIds: filter.departmentList,
          TemplateIds: filter.templateList,
        },
        method: HttpMethods.POST,
        url: `${Endpoints.Assignments}`,
      }),
      transformResponse: (response: AssignmentDTO[]) => {
        if (!response) return [];

        // Eat duplicate assignments NOM NOM NOM :cookie:
        const seenIds = new Set<number>();

        const result: Assignment[] = [];

        response.forEach((assignment) => {
          if (seenIds.has(assignment.assign_id)) return;

          seenIds.add(assignment.assign_id);

          result.push({
            assignId: assignment.assign_id,
            assignStructureId: assignment.assign_structure_id,
            assignTypeId: assignment.atype_id,
            autoAddAssignments: assignment.auto_add_assignments,
            category: assignment.category,
            compactName: assignment.compact_name,
            defaultStartTime: assignment.default_start_time,
            defaultStopTime: assignment.default_stop_time,
            departmentId: assignment.department_id,
            description: assignment.description,
            displayName: assignment.display_name,
            expired: assignment.expired,
            id: assignment.assign_structure_id,
            name: assignment.display_name,
            tallies: assignment.tallies,
            templateId: assignment.template_id,
            templateName: assignment.template_name,
          });
        });

        return result;
      },
    }),
  }),
});

export const { useGetAssignmentTypesQuery, useGetAssignmentsQuery } = assignmentsApi;
